import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import * as routes from '../Routes/routes';
import { removeProductBox } from '../../actions/product';
import Box from '@material-ui/core/Box';
import Title from '../Title';
import ProductCard from './ProductCard';
import Empty from '../Empty';
import ConfirmDialog from '../shared/ConfirmDialog';

// All plants in specific box

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    color: 'initial',
  },
}));

const ProductList = ({ productBoxProducts, match, history, removeProductBox }) => {
  const classes = useStyles();
  const {
    params: { productBox },
  } = match;

  const deleteProductBoxes = productBox => {
    removeProductBox(productBox).then(res => {
      if (res) {
        history.push(routes.PRODUCTBOXLIST);
      }
    });
  };

  return (
    <div>
      <Title title={`Alle planter i kasse ${productBox}`} />
      <Grid container alignItems="center">
        <Link
          to={{
            pathname: routes.ADDPRODUCT,
            state: {
              productBox,
            },
          }}
          className={classes.link}
        >
          <Button
            size="small"
            variant="outlined"
            color="primary"
            className={classes.button}
          >
            Tilføj ny plante til kasse {productBox}
          </Button>
        </Link>

        <ConfirmDialog confirmAction={() => deleteProductBoxes(productBox)} />
      </Grid>

      <Box display="flex" flexwrap="wrap">
        {productBoxProducts.length > 0 ? (
          productBoxProducts.map(product => (
            <ProductCard key={product.id} product={product} history={history} />
          ))
        ) : (
          <Empty />
        )}
      </Box>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    firestore: {
      ordered: { products },
    },
  } = state;
  const productBox = ownProps.match.params.productBox;
  const productBoxProducts = products
    ? products.filter(item => item.productBox === productBox)
    : [];
  return {
    productBoxProducts,
  };
};

export default connect(
  mapStateToProps,
  { removeProductBox },
)(ProductList);
