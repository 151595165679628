import React from 'react';
import { connect } from 'react-redux';

import ProductForm from './ProductForm';
import Title from '../Title';
import { DASHBOARD } from '../Routes/routes';
import { removeProduct, updateProduct } from '../../actions/product';
import ConfirmDialog from '../shared/ConfirmDialog';

const UpdateProduct = props => {

  const {
    updateProduct,
    removeProduct,
    history,
    productById,
    match: { params },
  } = props;

  const removeHandler = id => {
    removeProduct(id).then(res => {
      if (res) {
        history.push(`${DASHBOARD}/home`);
      }
    });
  };

  const update = product => {
    updateProduct(params.productId, product).then(res => {
      if (res) {
        history.push(`${DASHBOARD}/home`);
      }
    });
  };

  return (
    <div>
      <Title title="Opdater Produkt" />
      <ProductForm update={update} productById={productById} />
      <ConfirmDialog confirmAction={() => removeHandler(params.productId)} />

    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    firestore: {
      ordered: { products },
    },
  } = state;
  const productId = ownProps.match.params.productId;
  const productById = products && products.find(item => item.id === productId);
  return { productById };
};

export default connect(
  mapStateToProps,
  { removeProduct, updateProduct },
)(UpdateProduct);