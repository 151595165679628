export const SIGNUP = `/signup`;
export const SIGNIN = `/signin`;

export const DASHBOARD = `/dashboard`;
export const HOME = `${DASHBOARD}/home`;
export const ADDPRODUCT = `${DASHBOARD}/addproduct`;
export const PRODUCTBOXLIST = `${DASHBOARD}/box`;
export const PRODUCTARLIST = `${DASHBOARD}/productArts/art`;
export const PRODUCT = `${DASHBOARD}/product`;
export const SEARCH = `${DASHBOARD}/search`;
