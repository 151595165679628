import React from 'react';
import { Switch, Route } from 'react-router-dom';

import * as routes from './routes';
import Home from '../Home';
import AddProduct from '../Products/AddProduct';

import ProductBoxList from '../ProductBoxList';
// import ProductArtList from '../ProductArtList';

import ProductList from '../Products/ProductList';
import ProductListArt from '../Products/ProductArtList';

import UpdateProduct from '../Products/UpdateProduct';
import Search from '../Search';
import ProductDetail from '../Products/ProductDetail';

const ProductRouter = () => {
  return (
    <Switch>
      <Route
        exact
        path={routes.HOME}
        component={Home}
      />
      <Route
        path={routes.ADDPRODUCT}
        component={AddProduct}
      />
      <Route
        path={routes.PRODUCTBOXLIST}
        component={ProductBoxList}
      />
      {/* <Route
        path={routes.PRODUCTARLIST}
        component={ProductArtList}
      /> */}
      <Route
        path={routes.SEARCH}
        component={Search}
      />
      <Route
        exact
        path={`${routes.DASHBOARD}/art/:productArt`}
        component={ProductListArt}
      />
      <Route
        exact
        path={`${routes.DASHBOARD}/:productArt/:productId`}
        component={ProductDetail}
      />
      <Route
        exact
        path={`${routes.DASHBOARD}/:productBox`}
        component={ProductList}
      />
      <Route
        exact
        path={`${routes.DASHBOARD}/:productBox/:productId`}
        component={ProductDetail}
      />
      <Route
        exact
        path={`${routes.DASHBOARD}/:productBox/:productId/edit`}
        component={UpdateProduct}
      />
    </Switch>
  );
};

export default ProductRouter;
