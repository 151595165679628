import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    position:'absolute',
    right:'125px',
    width:'500px',
    overflow: 'auto',
    flexDirection: 'column',
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const NameSuggestion = props => {
  const classes = useStyles();
  const { products } = props;

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell>Navn</TableCell>
              <TableCell>Art</TableCell>
              <TableCell>Kasse</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products && products.length > 0 ? (
              products.map(product => (
                <TableRow key={product.id}>
                  <TableCell>
                      {product.name}
                  </TableCell>
                  <TableCell>{product.art}</TableCell>
                  <TableCell>{product.productBox}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell>Ingen nyelige tilføjet planet</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </Grid>
  );
};

export default NameSuggestion;
