import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {compose} from 'redux';
import {firestoreConnect} from 'react-redux-firebase';
import { connect } from 'react-redux';
import { DASHBOARD } from '../Routes/routes';

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 150,
  },
  productBox: {
    flex: 1,
  },
  link: {
    color: 'dodgerblue',
    textDecoration: 'none',
  },
}));

const ProductBox = props => {
  const classes = useStyles();
  const { productBox } = props;

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <Grid item xs={12} md={4} lg={3}>
      <Paper className={fixedHeightPaper}>
      <Link to={`${DASHBOARD}/${productBox}`} className={classes.link}>
        <Typography
          component="h1"
          variant="h4"
          color="primary"
          className={classes.productBox}
        >
          {productBox}
        </Typography>

            {`Vis planter i kasse ${productBox}`}
          </Link>
      </Paper>
    </Grid>
  );
};

ProductBox.propTypes = {
  productBox: PropTypes.number,
};

const mapStateToProps = state => {
  const {
    firestore: {
      ordered: { products },
    },
  } = state;
  return {
    products: state.firestore.ordered.products,
  };
};


export default compose(
  connect(mapStateToProps),
  firestoreConnect(() => [
    {
      collection: 'products',
      orderBy: ['productBox', 'asc'],
    },
  ])
)(ProductBox);