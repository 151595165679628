import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
  apiKey: "AIzaSyATG8XAuHXOkII4B1lnOHRMe-MZyonL4T8",
  authDomain: "holm-planteskole-v2.firebaseapp.com",
  databaseURL: "https://holm-planteskole-v2-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "holm-planteskole-v2",
  storageBucket: "holm-planteskole-v2.appspot.com",
  messagingSenderId: "612731519299",
  appId: "1:612731519299:web:5faade0ee24c867d712ff6",
  measurementId: "G-WYY240VM49",
};

firebase.initializeApp(config);

export default firebase 