import React from 'react';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

import ProductBox from './ProductBox';
import Title from '../Title';
import Empty from '../Empty';

const ProductBoxList = props => {
  const { productBoxes } = props;
  return (
    <React.Fragment>
      <Title title="Kasser" />
      <Box display="flex" flexWrap="wrap">
        {productBoxes && productBoxes.length > 0 ? (
          productBoxes.map(productBox => (
            <ProductBox key={productBox} productBox={productBox} />
          ))
        ) : (
            <Empty />
          )}
      </Box>
    </React.Fragment>
  );
};

ProductBoxList.propTypes = {
  productBoxes: PropTypes.arrayOf(PropTypes.string),
};

const getUniqueProductBoxes = products => {
  if (products) {
    const productBoxes = products.map(product => product.productBox);
    return productBoxes.filter((productBox, index) => {
      return productBoxes.indexOf(productBox) === index;
    });
  }
};

const mapStateToProps = state => {
  const {
    firestore: {
      ordered: { products },
    },
  } = state;
  const productBoxes = getUniqueProductBoxes(products);
  return {
    productBoxes,
  };
};

export default connect(mapStateToProps)(ProductBoxList);
