import React from 'react';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Title from '../Title';
import ProductCard from './ProductCard';
import Empty from '../Empty';

// All species in specific specie category

const ProductArtList = ({ productArtProducts, match, history }) => {
  const {
    params: { productArt },
  } = match;

  return (
    <div>
      <Title title={`Alle planter i arten: ${productArt}`} />

      <Box display="flex" flexwrap="wrap">
        {productArtProducts.length > 0 ? (
          productArtProducts.map(product => (
            <ProductCard key={product.id} product={product} history={history} />
          ))
        ) : (
          <Empty />
        )}
      </Box>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    firestore: {
      ordered: { products },
    },
  } = state;
  const productArt = ownProps.match.params.productArt;
  const productArtProducts = products
    ? products.filter(item => item.art === productArt)
    : [];
  return {
    productArtProducts,
  };
};

export default connect(
  mapStateToProps,
)(ProductArtList);
