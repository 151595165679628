import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Fuse from 'fuse.js';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import { useStyles } from '../auth/authStyles';
import FormButton from '../shared/FormButton';
import Box from '@material-ui/core/Box';
import NameSuggestion from '../shared/nameSuggestion';

const ProductForm = (props) => {
  const classes = useStyles();
  const { update, addProduct, productBoxName, productById, isLoading } = props;

  const [product, setProduct] = useState({
    name: '',
    art: '',
    age: '',
    size: '',
    proveniens: '',
    amount: '',
    productBox: '',
    description: '',
  });

  const [error, setError] = useState('');

  useEffect(() => {
    if (productBoxName) {
      setProduct(prev => ({
        ...prev,
        productBox: productBoxName,
      }));
    }
    if (productById) {
      setProduct(prev => ({
        ...prev,
        ...productById,
        updatedAt: new Date(),
      }));
    }
  }, [productBoxName, productById]);

  const handleSubmit = e => {
    e.preventDefault();
    const { name, art, productBox } = product;
    const formIsValid = name.trim() && art.trim() && productBox.trim();
    if (formIsValid) {
      setError('');
      return chooseFormAction();
    }
    return setError('Alle felter er obligatoriske');
  };

  const chooseFormAction = () => {
    if (!props.update) {
      addProduct(product);
    } else {
      update(product);
    }
  };

  const [searchTerm, setSearchTerm] = useState('');
  const fuseOptionsName = {
    shouldSort: true,
    threshold: 0.4,
    location: 0,
    distance: 50,
    maxPatternLength: 12,
    minMatchCharLength: 3,
    keys: ['name', 'productBox', 'art'],
  };

  const fuseName = new Fuse(props.products, fuseOptionsName);
  const data = searchTerm ? fuseName.search(searchTerm).reverse() : [];


  const inputChange = ({ target }) => {
    const name = target.name;
    const value = target.value;
    if (target.id == "name" || target.id == 'productBox') {
      setSearchTerm(target.value);
    }
    setProduct(prev => ({ ...prev, [name]: value }));
  };

  return (
    <Container component="main" maxWidth="xs">
      <form className={classes.form} onSubmit={handleSubmit} noValidate>
        {error && <p>{error}</p>}

        <Box display="flex" flexwrap="wrap">
          {data.length > 0
            ? (
              <React.Fragment>
                <NameSuggestion products={data} />
              </React.Fragment>
            ) : searchTerm.length > 0 && data.length < 1
              ? (
                <Typography variant="body2" color="secondary" component="p">
                </Typography>
              ) : (
                <Typography variant="body2" color="textSecondary" component="p">
                </Typography>
              )}
        </Box>


        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="Navn"
          name="name"
          autoComplete="productName"
          onChange={inputChange}
          value={product.name}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="art"
          label="Art"
          name="art"
          autoComplete="art"
          onChange={inputChange}
          value={product.art}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="age"
          label="Alder"
          name="age"
          onChange={inputChange}
          value={product.age}
        />
               <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="size"
          label="Størrelse"
          name="size"
          autoComplete="size"
          onChange={inputChange}
          value={product.size}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="proveniens"
          label="Proveniens"
          name="proveniens"
          autoComplete="proveniens"
          onChange={inputChange}
          value={product.proveniens}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="amount"
          label="Antal"
          name="amount"
          autoComplete="amount"
          onChange={inputChange}
          value={product.amount}
        />
        {!productBoxName && (
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="productBox"
            label="Kasse"
            name="productBox"
            autoComplete="productBox"
            onChange={inputChange}
            value={product.productBox}
          />
        )}
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="description"
          label="Beskrivelse"
          name="description"
          onChange={inputChange}
          value={product.description}
        />
        <FormButton
          isLoading={isLoading}
          text={
            update
              ? 'Gem ændringer'
              : productBoxName
                ? `Tilføj ny plante til kasse ${productBoxName}`
                : 'Tilføj ny plante'
          }
        />
      </form>
    </Container>
  );
};

ProductForm.propTypes = {
  products: PropTypes.array,
};

// const mapStateToProps = state => {
//   return {
//     isLoading: state.products.isLoading,
//   };
// };


const mapStateToProps = ({
  firestore: {
    ordered: { products },
  },
}) => ({ products });

export default connect(
  mapStateToProps,
  {},
)(ProductForm);
